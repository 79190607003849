import { omerge } from "@/brains/flang";
import {
  EDirectoryGET,
  EDirectoryGET_UserOrgDirectory_2,
  EKitUsersGET,
  EKitUsersGET_GroupKit_19,
  EKitUsersGET_UserOrgKit_16,
  EDirectoryGET_GroupDirectory_9,
} from "../types/api-schema";

export enum KitDirRole {
  admin = "admin",
  runner = "runner",
}
export enum AccessLevel {
  org = "org",
  dir = "dir",
  kit = "kit",
  user_kit = "user_kit",
  creator = "creator",
}
export type KitOrDirUserDoll = {
  role: KitDirRole;
  isDeleted: boolean;
  isPendingToOrg: boolean;
  email: string;
  name: string;
  accessLevel: AccessLevel;
  userId: string;
};
export type GroupDoll = {
  role: KitDirRole;
  groupId: number;
  name: string;
  accessLevel: AccessLevel;
  users: GroupUser[];
};

export type GroupUser = {
  userId: string;
  email: string;
  name: string;
  isPendingToFlank: boolean;
  isDeleted: boolean;
};

export type GroupShareRecipient = {
  groupId: number;
  newKitDirGroupRole: KitDirRole;
};

function _initKitOrDirGroupDollsFromKitUsers(kuObj: EKitUsersGET) {
  return kuObj.groups.map((g: EKitUsersGET_GroupKit_19) => {
    return omerge({
      role: g.role as KitDirRole,
      groupId: g.groupId,
      name: g.name,
      accessLevel: g.accessLevel as AccessLevel,
      users: g.users as GroupUser[],
    }) as GroupDoll;
  });
}

function _initUserDolls(kuObj: EKitUsersGET): KitOrDirUserDoll[] {
  return kuObj.nonGroupUsers.map((u: EKitUsersGET_UserOrgKit_16) => {
    return {
      role: u.role as KitDirRole,
      isDeleted: u.isDeleted,
      isPendingToOrg: u.isPendingToOrg,
      email: u.email,
      name: u.name,
      accessLevel: u.accessLevel as AccessLevel,
      userId: u.userId,
    } as KitOrDirUserDoll;
  });
}

function _initKitUserDolls(kuObj: EKitUsersGET): KitOrDirUserDoll[] {
  return _initUserDolls(kuObj);
}

function _initKitOrDirUserDollsFromKitUsers(
  kuObj: EKitUsersGET
): KitOrDirUserDoll[] {
  return _initUserDolls(kuObj) as KitOrDirUserDoll[];
}

function _initKitOrDirUserDollsFromDir(dir: EDirectoryGET): KitOrDirUserDoll[] {
  return dir.uods.map((uod: EDirectoryGET_UserOrgDirectory_2) => {
    return {
      role: uod.role as KitDirRole,
      isDeleted: uod.isDeleted ?? false,
      isPendingToOrg: uod.isPendingToOrg ?? false,
      email: uod.email ?? "",
      name: uod.name ?? "",
      accessLevel: uod.accessLevel as AccessLevel,
      userId: uod.userId ?? "",
    };
  });
}

function _initGroupDollsFromDir(dir: EDirectoryGET): GroupDoll[] {
  return dir.groups.map((g: EDirectoryGET_GroupDirectory_9) => {
    return {
      role: g.role as KitDirRole,
      groupId: g.groupId,
      name: g.name ?? "",
      accessLevel: AccessLevel.dir as AccessLevel,
      users: g.users as GroupUser[],
    };
  });
}

function _initAllUsersFromKitUsers(kuObj: EKitUsersGET): KitOrDirUserDoll[] {
  return kuObj.allUsers.map((u: any) => {
    return {
      isDeleted: u.isDeleted,
      email: u.email,
      name: u.name,
      userId: u.userId,
    } as KitOrDirUserDoll;
  });
}

function _initAllUsersFromDirUsers(dir: EDirectoryGET): KitOrDirUserDoll[] {
  return dir.allUsers.map((u: any) => {
    return {
      isDeleted: u.isDeleted,
      email: u.email,
      name: u.name,
      userId: u.userId,
    } as KitOrDirUserDoll;
  });
}

export default {
  initKitUserDolls: _initKitUserDolls,
  initKitOrDirUserDollsFromKitUsers: _initKitOrDirUserDollsFromKitUsers,
  initKitOrDirUserDollsFromDir: _initKitOrDirUserDollsFromDir,
  initKitOrDirGroupDollsFromKitUsers: _initKitOrDirGroupDollsFromKitUsers,
  initGroupDollsFromDir: _initGroupDollsFromDir,
  initAllUsersFromKitUsers: _initAllUsersFromKitUsers,
  initAllUsersFromDirUsers: _initAllUsersFromDirUsers,
};

// https://patorjk.com/software/taag/#p=display&f=Colossal&t=Math
