import { render, staticRenderFns } from "./CredentialCollapseBody2.vue?vue&type=template&id=b3527a1c&scoped=true&"
import script from "./CredentialCollapseBody2.vue?vue&type=script&lang=ts&"
export * from "./CredentialCollapseBody2.vue?vue&type=script&lang=ts&"
import style0 from "./CredentialCollapseBody2.vue?vue&type=style&index=0&id=b3527a1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3527a1c",
  null
  
)

export default component.exports