import Vue from "vue";
import Router from "vue-router";
import api from "@/shelves/api";
import LegosHQ from "../components/lego-config/LegosHQ.vue";
import LandingPageWrapper from "@/components/app-stuff/landing/LandingPageWrapper.vue";
import Credentials from "../components/resources/Credentials.vue";
import Resources from "../components/resources/Resources.vue";
import OrgView from "../components/org-configuration/OrgView.vue";
import ManageOrgsView from "../components/org-configuration/ManageOrgsView.vue";
import HistoryView from "../components/runs/HistoryView.vue";
import SetupOrg from "@/components/org-configuration/SetupOrg.vue";
import WhoopsView from "../components/app-stuff/WhoopsView.vue";
import PageDNEView from "../components/app-stuff/PageDNEView.vue";
import DiagnosticsView from "../components/junk-drawer/DiagnosticsView.vue";
import KitBuilderWrapper from "../components/kit-builder/KitBuilderWrapper.vue";
import Sandflox from "../components/junk-drawer/Sandflox.vue";
import Blank from "../components/junk-drawer/Blank.vue";
import Analytics from "../components/junk-drawer/Analytics.vue";
import UserDrilldown from "../components/org-configuration/UserDrilldown.vue";
import SignupPage from "../components/org-configuration/create-org-view/SignupPage.vue";
import OrgCronJobs from "../components/org-configuration/OrgCronJobs.vue";
import SyncView from "../components/sync/SyncView.vue";
import StripePayment from "../components/payments/StripePayment.vue";
import CopyAuthToken from "../components/app-stuff/CopyAuthToken.vue";
import { getAuthInstance } from "@/plugins/auth";
import { store } from "@/plugins/store";
import { oget, nullish } from "@/brains/flang";
import { redToast } from "@/components/comflonents/buefyActions";

Vue.use(Router);
function waitUntilAuthPluginCreated(to, from, next) {
  console.log("waitUntilAuthPluginCreated");
  const proceedIfAuth = (auth, _to) => {
    if (!auth.isAuthenticated) {
      console.log("next");
      // this will keep the URL intact, but display the LoginBody if it's a login-protected page
      next();
      return;
    }

    // i.e. if path does not have /:orgId/ in it, continue
    if (nullish(oget(_to, "params.orgId"))) {
      next();
      return;
    }

    if (store.state.activeOrg.orgId == _to.params.orgId) {
      next();
    } else if (store.state.orgs.some((e) => e.orgId == _to.params.orgId)) {
      store.commit("setActiveOrgById", _to.params.orgId);
      next();
    } else {
      next("/orgs");
      // TODO: what's a cleaner way to get access to Vue instance from ts files than getAuthInstance()?
      redToast(
        getAuthInstance(),
        `The URL was coded for organization with ID "${_to.params.orgId}", but you don't have access to that org`,
        7000
      );
    }
  };

  const auth = getAuthInstance();

  if (!auth.authPluginBeingCreated) {
    proceedIfAuth(auth);
  } else {
    auth.$watch("authPluginBeingCreated", (authPluginBeingCreated) => {
      if (authPluginBeingCreated === false) {
        proceedIfAuth(auth, to);
      }
    });
  }
}

function skipLandingIfAuthenticated(to, from, next) {
  console.log("skipLandingIfAuthenticated");
  const redirectIfAuth = (auth) => {
    if (auth.isAuthenticated && store.state.activeOrg) {
      next({
        name: "legosHQRoute",
        params: { orgId: store.state.activeOrg.orgId },
      });
    } else if (
      auth.isAuthenticated &&
      process.env.VUE_APP_FLANK_META_ENV != "private_ad"
    ) {
      next({ name: "createOrgRoute" });
    } else {
      next();
    }
  };

  const auth = getAuthInstance();

  if (auth.logoutInProgress) {
    // when we logout, we redirect to the home page before we actually logout
    // and set isAuthenticated to false
    console.log("logout in progress");
    next();
  } else if (!auth.authPluginBeingCreated) {
    console.log("redirectIfAuth");
    redirectIfAuth(auth);
  } else {
    // if auth plugin is being created, wait until it's done
    console.log("watching authPluginBeingCreated");
    auth.$watch("authPluginBeingCreated", (authPluginBeingCreated) => {
      console.log("authPluginBeingCreated changed");
      if (authPluginBeingCreated === false) {
        redirectIfAuth(auth);
      }
    });
  }
}

const beforeEnterChecker = (to, from, next) => {
  if (!(to.meta?.beforeEnterChecks?.responsibleForTurningOffSplash ?? false)) {
    console.log(to.name);
    console.log("setting is loading to false");
    store.commit("setIsLoading", false);
  }
  if (to.meta?.beforeEnterChecks?.waitUntilAuthPluginCreated ?? false) {
    waitUntilAuthPluginCreated(to, from, next);
  } else if (to.meta?.beforeEnterChecks?.skipLandingIfAuthenticated ?? false) {
    skipLandingIfAuthenticated(to, from, next);
  } else {
    next();
  }
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "landingRoute",
      component: LandingPageWrapper,
      meta: {
        viewableWithoutBeingSignedIn: true,
        beforeEnterChecks: { skipLandingIfAuthenticated: true },
        hideNavBar: true,
      },
      beforeEnter: beforeEnterChecker,
    },
    // {
    //   path: "/pricing",
    //   name: "pricing",
    //   component: Pricing,
    //   meta: { viewableWithoutBeingSignedIn: true, title: "Pricing" },
    //   beforeEnter: beforeEnterChecker,
    // },
    {
      path: "/signup",
      name: "signupPage",
      component: SignupPage,
      meta: { viewableWithoutBeingSignedIn: true, title: "Signup" },
      beforeEnter: beforeEnterChecker,
    },

    // ------------------------------------------
    // Core App pages
    // ------------------------------------------
    {
      path: "/:orgId/builder",
      name: "kitBuilderRoute",
      component: KitBuilderWrapper,
      props: true,
      meta: {
        viewableWithoutBeingSignedIn: true,
        beforeEnterChecks: { responsibleForTurningOffSplash: true },
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/commands",
      name: "legosHQRoute",
      component: LegosHQ,
      props: true,
      meta: {
        waitUntilAuthPluginCreated: true,
        beforeEnterChecks: { responsibleForTurningOffSplash: true },
        title: "Commands",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/history",
      name: "historyRoute",
      component: HistoryView,
      props: true,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "History",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/analytics",
      name: "analyticsRoute",
      component: Analytics,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Analytics",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/sync",
      name: "syncRoute",
      component: SyncView,
      props: true,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Sync",
      },
      beforeEnter: beforeEnterChecker,
    },
    // ------------------------------------------
    // Org settings
    // ------------------------------------------
    {
      path: "/:orgId/credentials",
      name: "credentialsRoute",
      component: Credentials,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Credentials",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/resources",
      name: "resourcesRoute",
      component: Resources,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Resources",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/org",
      name: "orgRoute",
      component: OrgView,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Org",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/members/:userId",
      name: "userDrilldownRoute",
      component: UserDrilldown,
      props: true,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "User",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/:orgId/cron-jobs",
      name: "orgCronJobsRoute",
      component: OrgCronJobs,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Scheduled Jobs",
      },
      beforeEnter: beforeEnterChecker,
    },

    // ------------------------------------------
    // User settings
    // ------------------------------------------
    {
      path: "/orgs",
      name: "manageOrgsRoute",
      component: ManageOrgsView,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Manage Orgs",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/create-org",
      name: "createOrgRoute",
      component: SetupOrg,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Create Org",
      },
      beforeEnter: beforeEnterChecker,
    },

    // ------------------------------------------
    // Utilities
    // ------------------------------------------
    {
      path: "/copy-token",
      name: "copyTokenRoute",
      component: CopyAuthToken,
      meta: {
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Copy Token",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/diagnostics",
      name: "diagnosticsRoute",
      component: DiagnosticsView,
      meta: {
        viewableWithoutBeingSignedIn: true,
        beforeEnterChecks: { waitUntilAuthPluginCreated: true },
        title: "Diagnostics",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/sandflox",
      name: "sandfloxRoute",
      component: Sandflox,
      meta: {
        hideNavBar: true,
        viewableWithoutBeingSignedIn: true,
        title: "Sandflox",
      },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/loading",
      name: "loadingRoute",
      component: Blank,
      meta: { beforeEnterChecks: { responsibleForTurningOffSplash: true } },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/blank",
      name: "blankRoute",
      component: Blank,
      meta: { hideNavBar: true },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "/whoops",
      name: "whoopsRoute",
      component: WhoopsView,
      props: true,
      meta: { hideNavBar: true, title: "Whoops" },
      beforeEnter: beforeEnterChecker,
    },
    {
      path: "*",
      name: "pageDNERoute",
      component: PageDNEView,
      meta: { title: "Page Not Found" },
      beforeEnter: beforeEnterChecker,
    },
    // ------------------------------------------
    // Payments
    // ------------------------------------------
    // {
    //   path: "/:orgId/payments",
    //   name: "paymentsRoute",
    //   component: StripePayment,
    // meta: { beforeEnterChecks: { waitUntilAuthPluginCreated: true } },
    // beforeEnter: beforeEnterChecker,
    // },
  ],
});

router.afterEach((to, from) => {
  if (to.meta?.title) {
    document.title = to.meta?.title;
  }
});
export default router;
