var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.kitAccessCheckInProgress)?_c('b-loading',{attrs:{"can-cancel":false,"is-full-page":false},model:{value:(_vm.kitAccessCheckInProgress),callback:function ($$v) {_vm.kitAccessCheckInProgress=$$v},expression:"kitAccessCheckInProgress"}}):(!_vm.kitAccessCheckInProgress && _vm.notPublicAndNotAuthenticated)?_c('LoginBody'):(!_vm.kitAccessCheckInProgress && _vm.authenticatedButKitDNEAndNotBlank)?_c('PageDNEView',{attrs:{"freshKitButton":!_vm.kitExists && _vm.inUrlOrg}}):(
    !_vm.kitAccessCheckInProgress &&
    _vm.$auth.isAuthenticated &&
    _vm.kitExists &&
    !_vm.isPublic &&
    !_vm.userHasAccess
  )?_c('KitNoAccess'):(
    !_vm.kitAccessCheckInProgress && _vm.kitExistsAndIsPublicOrUserHasAccessORitsBlank
  )?_c('KitBuilder'):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }