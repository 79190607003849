import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { FlankUser, BulkRunPayload } from "@/types/general-flank-types";
import { Org } from "@/types/api-schema";
import { oget, pathJoin, okeys } from "@/brains/flang";

Vue.use(Vuex);

const defaultState = () => {
  return {
    flankUser: {} as FlankUser,
    directoriesCollapsed: [] as { directoryId: number; isCollapsed: boolean }[],
    orgs: [] as Org[],
    activeOrg: null as Org | null,
    createOrgActiveStep: null,
    latestSidebarTab: "my-legos",
    hqOpenDirectories: [],
    isLoading: false,
    flankEnv: {
      $$FLANK_USER_ID$$: "",
      $$FLANK_USER_EMAIL$$: "",
      $$FLANK_USER_NAME$$: "",
      // other variables as defined by the org's runOnStartup commands
    },
    wormhole: {
      setElementsToPropElements: { signal: 0, params: {} },
      runLego: { signal: 0, params: {} },
      bulkRun: { signal: 0, params: {} as BulkRunPayload },
      openShareKitModal: { signal: 0, params: {} },
      removeLego: { signal: 0, params: {} },
      clearParamSender: { signal: 0, params: {} },
      pushLoadingText: { signal: 0, params: {} },
      saveThisAs: { signal: 0, params: {} },
      injectRunrams: { signal: 0, params: {} },
    },
    noLegosStep: [
      {
        target: "#v-step-0",
        content: `<strong class="tw-text-lg tw-text-white"> Sync AWS and Flank </strong><br>Flank reads your AWS Lambda IDs for the purpose of running them.`,
        params: {
          enableScrolling: false,
        },
      },
    ],
    mainSteps: [
      {
        target: "#v-step-1",
        content: `<strong class="tw-text-lg tw-text-white"> Run Command </strong><br>Flank runs Lambdas in your AWS environment.<div class="tw-text-xs"> (1 of 3)</div>`,
        params: {
          enableScrolling: false,
        },
      },
      {
        target: "#v-step-2",
        content: `<strong class="tw-text-lg tw-text-white"> Share Command </strong><br>Control who can run and share Lambdas.<div class="tw-text-xs"> (2 of 3)</div>`,
        params: {
          enableScrolling: false,
        },
      },
      {
        target: "#v-step-3",
        content: `<strong class="tw-text-lg tw-text-white"> View History </strong><br> Who ran what? When did they run it? What data did the run return?<div class="tw-text-xs"> (3 of 3)</div>`,
        params: {
          enableScrolling: false,
        },
      },
    ],
    options: {
      useKeyboardNavigation: true,
      labels: {
        buttonSkip: "Done",
        buttonPrevious: "Previous",
        buttonNext: "Next",
        buttonStop: "Got it",
      },
    },
  };
};

export const store = new Vuex.Store({
  state: defaultState(),
  plugins: [createPersistedState()],
  getters: {
    activeOrgIsSandbox: (state): boolean => {
      if (state.activeOrg == null) {
        return false;
      }
      return state.activeOrg.isSandbox ?? false;
    },
    thereAreNoOrgs: (state): boolean => {
      return state.orgs.length == 0;
    },
    thereAreOnlySandboxes: (state): boolean => {
      const sandboxes = state.orgs.filter((o: Org) => o.isSandbox);
      return sandboxes.length == state.orgs.length;
    },
  },
  actions: {},
  mutations: {
    wormhole(state, { method, params = {} }: { method: string; params: any }) {
      const newVal = {
        signal: (state.wormhole as any)[method].signal + 1,
        params: params,
      };
      (state.wormhole as any)[method] = newVal;
    },
    setActiveOrgBlobLocation(state, newBlobLocation: string) {
      if (state.activeOrg) {
        state.activeOrg.blobLocation = newBlobLocation;
        const orgId = state.activeOrg.orgId;
        const match = state.orgs.find((o) => o.orgId == orgId);
        if (match) {
          match.blobLocation = newBlobLocation;
        }
      }
    },
    setActiveOrgAdAdminEmails(state, adAdminEmails: string) {
      if (state.activeOrg) {
        state.activeOrg.adAdminEmails = adAdminEmails;
        const orgId = state.activeOrg.orgId;
        const match = state.orgs.find((o) => o.orgId == orgId);
        if (match) {
          match.adAdminEmails = adAdminEmails;
        }
      }
    },
    setDirectoryCollapsed(
      state,
      update: { directoryId: number; isCollapsed: boolean }
    ) {
      const match = state.directoriesCollapsed.find(
        (d: any) => d.directoryId == update.directoryId
      );
      if (match) {
        match.isCollapsed = update.isCollapsed;
      } else {
        state.directoriesCollapsed.push({
          directoryId: update.directoryId,
          isCollapsed: update.isCollapsed,
        });
      }
    },
    addNewOrg(state, org) {
      state.orgs.push(org);
    },
    setLatestSidebarTab(state, latestTab) {
      state.latestSidebarTab = latestTab;
    },
    setHqOpenDirectories(state, openDirectories) {
      state.hqOpenDirectories = openDirectories;
    },
    setActiveOrgById(state, orgId: string) {
      const match = state.orgs.find((o) => o.orgId == orgId);
      state.activeOrg = match ?? null;
    },
    setOrgs(state, orgs) {
      state.orgs = orgs;
    },
    setFlankUser(state, flankUser) {
      state.flankUser = flankUser;
    },
    setFlankEnvUserId(state, userId) {
      state.flankEnv.$$FLANK_USER_ID$$ = userId;
    },
    setFlankEnvUserEmail(state, email) {
      state.flankEnv.$$FLANK_USER_EMAIL$$ = email;
    },
    setFlankEnvUserName(state, name) {
      state.flankEnv.$$FLANK_USER_NAME$$ = name;
    },
    setFlankEnvOtherVars(state, otherVars) {
      okeys(otherVars).forEach((v: string) => {
        //@ts-ignore
        state.flankEnv[v] = oget(otherVars, v);
      });
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setCreateOrgActiveStep(state, createOrgActiveStep) {
      state.createOrgActiveStep = createOrgActiveStep;
    },
    reset(state) {
      Object.assign(state, defaultState());
    },
  },
});

export function createTwoWayBinding(propertyName: string, parentPath = "") {
  const mutationName = `set${propertyName
    .charAt(0)
    .toUpperCase()}${propertyName.slice(1)}`;
  return {
    get(): any {
      return oget(store.state, pathJoin(parentPath, propertyName));
    },
    set(value: any) {
      store.commit(mutationName, value);
    },
  };
}
