import Vue from "vue";
import CopyModal from "@/components/comflonents/CopyModal.vue";

export function sidePanel(
  that: Vue,
  component: string,
  width: string,
  props: any
) {
  return that.$showPanel({
    component: component,
    openOn: "right",
    width: width,
    props: props,
  });
}
export function greenToast(that: Vue, message: string, duration = 4000) {
  _toast(that, message, "is-success", duration);
}
export function redToast(that: Vue, message: string, duration = 4000) {
  _toast(that, message, "is-danger", duration);
}
export function blueToast(that: Vue, message: string, duration = 4000) {
  _toast(that, message, "is-info", duration);
}
function _toast(that: Vue, message: string, color: string, duration = 4000) {
  that.$buefy.toast.open({
    duration: duration,
    message: message,
    position: "is-top-right",
    type: color,
    queue: false,
  });
}
export function awaitableConfirmationPurple(
  that: Vue,
  title: string,
  message: string,
  confirmText: string,
  cancelText: string
): Promise<boolean> {
  return _awaitableConfirmation(
    that,
    title,
    message,
    confirmText,
    cancelText,
    "is-primary"
  );
}
export function awaitableConfirmationRed(
  that: Vue,
  title: string,
  message: string,
  confirmText: string,
  cancelText: string
) {
  return _awaitableConfirmation(
    that,
    title,
    message,
    confirmText,
    cancelText,
    "is-danger"
  );
}
export function awaitableAlertPurple(
  that: Vue,
  title: string,
  message: string,
  confirmText: string
) {
  return _awaitableAlert(that, title, message, confirmText, "is-primary");
}
export function awaitablePromptPurple(
  that: Vue,
  title: string,
  message: string,
  confirmText: string,
  { inputType = "text", inputValue = "", inputPlaceholder = "" } = {
    inputType: "text",
    inputValue: "",
    inputPlaceholder: "",
  }
) {
  return _awaitablePrompt(that, title, message, confirmText, "is-primary", {
    inputType,
    inputValue,
    inputPlaceholder,
  });
}
export function copyModal(
  that: Vue,
  header: string,
  message = "",
  markdown: string | null = null
) {
  return _copyModal(that, header, message, markdown);
}
export function copyModalWithTabs(
  that: Vue,
  header: string,
  tabs: any | null = null
) {
  return _copyModal(that, header, "", null, tabs);
}
function _copyModal(
  that: Vue,
  header: string,
  message = "",
  markdown: string | null = null,
  tabs: any | null = null
) {
  that.$buefy.modal.open({
    parent: that,
    component: CopyModal,
    hasModalCard: true,
    trapFocus: true,
    props: {
      message: message,
      header: header,
      markdown: markdown,
      tabs: tabs,
    },
  });
}

function _awaitableConfirmation(
  that: Vue,
  title: string,
  message: string,
  confirmText: string,
  cancelText: string,
  type: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    that.$buefy.dialog.confirm({
      title: title,
      message: message,
      confirmText: confirmText,
      cancelText: cancelText,
      type: type,
      onCancel: () => resolve(false),
      onConfirm: () => resolve(true),
    });
  });
}
function _awaitableAlert(
  that: Vue,
  title: string,
  message: string,
  confirmText: string,
  type: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    that.$buefy.dialog.alert({
      title: title,
      message: message,
      confirmText: confirmText,
      type: type,
      onConfirm: () => resolve(true),
    });
  });
}

function _awaitablePrompt(
  that: Vue,
  title: string,
  message: string,
  confirmText: string,
  type: string,
  { inputType, inputValue, inputPlaceholder } = {
    inputType: "text",
    inputValue: "",
    inputPlaceholder: "",
  }
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    that.$buefy.dialog.prompt({
      title: title,
      message: message,
      confirmText: confirmText,
      type: type,
      onConfirm: () => resolve(true),
      inputAttrs: {
        type: inputType,
        value: inputValue,
        placeholder: inputPlaceholder,
      },
    });
  });
}
