import { render, staticRenderFns } from "./SendSidePanel.vue?vue&type=template&id=771c3108&scoped=true&"
import script from "./SendSidePanel.vue?vue&type=script&lang=ts&"
export * from "./SendSidePanel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "771c3108",
  null
  
)

export default component.exports