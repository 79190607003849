import { htmlSafe } from "@/brains/flang";
import { zz } from "@/brains/malf";

export function kbScrollToParam(legoPath: string, elementPath: string) {
  scrollToId("kit-builder", htmlSafe(`kb-${legoPath}.${elementPath}`));
}
export function kbScrollToLegoInstance(legoPath: string) {
  scrollToId("kit-builder", htmlSafe(`kb-li-${legoPath}`), 120);
}
export function kbScrollToOutput(legoPath: string) {
  console.log("scroll to kb");
  scrollToId("kit-builder", htmlSafe(`kb-output-${legoPath}`), 120);
}
export function pspScrollToOutput(legoPath: string) {
  console.log("scroll to psp");
  scrollToId("param-side-panel", htmlSafe(`psp-output-${legoPath}`), 120);
}
export function pspScrollToDropdownButtons() {
  scrollToId("param-side-panel", htmlSafe(`dropdown-badges`), 80);
}
export function syncDemoScrollToLego(tabId: number) {
  scrollToId("sync-demo", htmlSafe(`sync-demo-lego-${tabId}`), 10);
}
export function legoHqScrollToDir(directoryId: number) {
  scrollToId("legos-hq", htmlSafe(`legos-hq-dir-${directoryId}`), 120);
}
export function scrollToId(
  parentId: string,
  targetId: string,
  pushDownPage = 0.35
) {
  if (!parentId.startsWith("#")) parentId = "#" + parentId;
  if (!targetId.startsWith("#")) targetId = "#" + targetId;
  const target = document.querySelector(targetId);
  const parent = document.querySelector(parentId);
  if (!parent) {
    zz(`${parentId} not found in DOM while trying to scroll!`);
    return;
  }
  if (!target) {
    zz(`${targetId} not found in DOM while trying to scroll!`);
    return;
  }
  const viewportToParent = parent.getBoundingClientRect().y;
  const viewportToTarget = target.getBoundingClientRect().y;
  const parentCurrentScroll = parent.scrollTop;
  const parentToTarget =
    viewportToTarget - viewportToParent + parentCurrentScroll;
  if (pushDownPage < 1 && pushDownPage > 0) {
    pushDownPage = window.innerHeight * pushDownPage;
  }
  zz({ viewportToTarget });
  zz({ viewportToParent });
  zz({ parentCurrentScroll });
  zz({ parentToTarget });
  zz({ pushDownPage });
  zz({
    targetRect: document.querySelector(targetId)?.getBoundingClientRect(),
  });
  zz({ parentRect: parent.getBoundingClientRect() });

  parent.scrollTo({
    left: 0,
    top: parentToTarget - pushDownPage,
    behavior: "smooth",
  });
}
