var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-6"},_vm._l((_vm.kitDollsForRender),function(kd){return _c('div',{key:kd.kitInstanceId,staticClass:"tw-flex tw-flex-col tw-gap-y-2"},_vm._l((kd.legoDolls),function(ld){return _c('Box3D',{key:_vm.kdLdToPath(kd, ld),staticClass:"tw-w-80",class:!_vm.clickOnSchemaElements &&
          _vm.kdLdToPath(kd, ld) !== _vm.disablePath &&
          !_vm.disablePaths.includes(_vm.kdLdToPath(kd, ld))
            ? 'tw-cursor-pointer'
            : '',attrs:{"x":-20,"y":150},nativeOn:{"click":function($event){return _vm.handleLegoClick(kd, ld)}}},[_c('div',{staticClass:"tw-p-3 tw-pl-4 tw-border-2",class:_vm.kdLdToPath(kd, ld) !== _vm.disablePath &&
            !_vm.disablePaths.includes(_vm.kdLdToPath(kd, ld))
              ? ''
              : 'tw-text-gray-400 tw-bg-gray-50'},[_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-x-4 tw-items-center"},[_c('LegoLogo',{attrs:{"size":"is-small","cloudId":ld.cloudId,"imageUrl":ld.apiImageUrl}}),_c('div',{class:_vm.clickOnSchemaElements &&
                _vm.kdLdToPath(kd, ld) !== _vm.disablePath &&
                !_vm.disablePaths.includes(_vm.kdLdToPath(kd, ld))
                  ? 'tw-font-bold tw-text-gray-500'
                  : ''},[_vm._v(" "+_vm._s(ld.legoName)+" ")])],1),(
              _vm.clickOnSchemaElements &&
              _vm.kdLdToPath(kd, ld) !== _vm.disablePath &&
              !_vm.disablePaths.includes(_vm.kdLdToPath(kd, ld))
            )?_c('div',_vm._l((ld.kitRunSchema),function(element){return _c('a',{key:element.key,on:{"click":function($event){return _vm.handleElementClick(kd, ld, element.key)}}},[_vm._v(" "+_vm._s(element.key)+" ")])}),0):_vm._e()])])}),1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }