export function isLambda(cloudId: string): boolean {
  return cloudId.startsWith("arn:aws:lambda:");
}

// isLambda("arn:aws:lambda:us-west-2:590194043634:function:addUsers-falcon"); //=
// isLambda("arn:aws:step-function:us-west-2:590194043634:function:something"); //=

export function isStepFunction(cloudId: string): boolean {
  return cloudId.startsWith("arn:aws:states:");
}

// isStepFunction(
//   "arn:aws:states:us-east-1:443117978555:stateMachine:DataPLSM-zuDZMM92I6s3"
// ); //=
// isStepFunction(
//   "arn:aws:lambda:us-east-2:881222034154:function:addNewEntryConflictTimelineIndex"
// ); //=

export function isAzureFunction(cloudId: string): boolean {
  return (
    cloudId.match(
      "^azure::/subscriptions/[^/]+/resourceGroups/[^/]+/providers/Microsoft.Web/sites/[^/]+/functions/[^/]+$"
    ) !== null
  );
}

// const isf = isAzureFunction(
//   "azure::/subscriptions/30e7b10b-eab7-4bb2-8788-e3f7a2fc175e/resourceGroups/110testfunctionnameparam/providers/Microsoft.Web/sites/1-10-TestFunctionNameParam/functions/function-12-7"
// );
// isf; //=

// const notf = isAzureFunction(
//   "NOTazure::/subscriptions/30e7b10b-eab7-4bb2-8788-e3f7a2fc175e/resourceGroups/110testfunctionnameparam/providers/Microsoft.Web/sites/1-10-TestFunctionNameParam/functions/function-12-7"
// );
// notf; //=

export function isMSSQL(cloudId: string): boolean {
  return cloudId.startsWith("db::mssql::");
}

// isMSSQL("db::mssql::fomf-sandbox-ssdb.database.windows.net::fomf-ss-sandbox-db::dbo::function::addition_scalar") //=
// isMSSQL("db::postgres::fomf-sandbox-ssdb.database.windows.net::fomf-ss-sandbox-db::dbo::function::addition_scalar") //=
