import { v4 as uuidv4 } from "uuid";

export class DemoClient {
  userId: string;

  constructor() {
    this.userId = uuidv4();
  }
  getUser(): string | null {
    return localStorage.getItem("demoUserId");
  }
  login() {
    localStorage.setItem("demoUserId", this.userId);
  }
  logout() {
    localStorage.removeItem("demoUserId");
  }
  isAuthenticated() {
    return localStorage.getItem("demoUserId") !== null;
  }
}

let _demo: DemoClient | null = null;

export function demoClient(): DemoClient {
  if (_demo !== null) {
    return _demo;
  }
  _demo = new DemoClient();
  return _demo;
}
