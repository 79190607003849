import { audience, clientId, domain } from "../../auth_config.json";
import { Auth0Client } from "@auth0/auth0-spa-js";
import createAuth0Client from "@auth0/auth0-spa-js";

let _auth0: Auth0Client | null = null;

export async function auth0Client(): Promise<Auth0Client> {
  if (_auth0 !== null) {
    return _auth0;
  }
  _auth0 = await createAuth0Client({
    domain: domain,
    client_id: clientId,
    audience: audience,
    redirect_uri: window.location.origin + "/loading",
    cacheLocation: "localstorage",
  });
  return _auth0;
}
