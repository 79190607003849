import * as Msal from "@azure/msal-browser";
import { clientId, authority, scope } from "../../azure_auth_config.json";
import { lfirst } from "@/brains/flang";

class adClientWrapper {
  _adClient: Msal.PublicClientApplication;

  constructor(adClient: Msal.PublicClientApplication) {
    this._adClient = adClient;
  }

  isAuthenticated(): boolean {
    return this._adClient.getAllAccounts().length > 0;
  }
  getUser(): Msal.AccountInfo | null {
    return lfirst(this._adClient.getAllAccounts());
  }
  loginPopup(): Promise<Msal.AuthenticationResult> {
    return this._adClient.loginPopup({ scopes: [scope] });
  }
  getTokenSilentlyOrPop(): Promise<void | string | Msal.AuthenticationResult> {
    return this.acquireTokenSilently()
      .then((r: void | Msal.AuthenticationResult) => {
        if (r && "accessToken" in r) {
          return r.accessToken;
        }
        return;
      })
      .catch(async (error) => {
        if (error instanceof Msal.InteractionRequiredAuthError) {
          console.log("AD InteractionRequiredAuthError", error);
          console.log("needs interactive login");
          // fallback to interaction when silent call fails
          return await this.acquireTokenPopup().catch((error) => {
            //handleError(error);
            console.log("acquireTokenPopup Error");
            console.log(error);
          });
        } else {
          console.log("AD Non-Interactive AcquireTokenSilentError", error);
          throw error;
        }
      });
  }
  acquireTokenSilently(): Promise<void | Msal.AuthenticationResult> {
    const account = adClient().getUser();
    if (account) {
      const silentRequest = {
        scopes: [scope],
        account: account,
        forceRefresh: false,
      };
      return this._adClient.acquireTokenSilent(silentRequest);
    }
    return new Promise(() => {
      return;
    });
  }
  acquireTokenPopup(): Promise<void | Msal.AuthenticationResult> {
    const account = adClient().getUser();
    if (account) {
      const request = {
        scopes: [scope],
        loginHint: account.username,
      };
      return this._adClient.acquireTokenPopup(request);
    }
    return new Promise(() => {
      return;
    });
  }
  logoutPopup(): Promise<void> {
    const logoutRequest = {
      account: adClient().getUser(),
      mainWindowRedirectUri: window.location.origin,
    };
    return this._adClient.logoutPopup(logoutRequest);
  }
}

let _ad: adClientWrapper | null = null;

export function adClient(): adClientWrapper {
  if (_ad !== null) {
    return _ad;
  }
  _ad = new adClientWrapper(
    new Msal.PublicClientApplication({
      auth: {
        clientId: clientId,
        redirectUri: window.location.origin + "/blank",
        authority: authority,
      },
      cache: { cacheLocation: "localStorage" },
    })
  );
  return _ad;
}
