var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-h-full tw-w-full tw-relative",attrs:{"data-flank":"side-panel-navigator"}},[(_vm.isNested)?_c('div',{staticClass:"tw-bg-gray-200 tw-opacity-30 tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0",attrs:{"data-flank":"nested-pop-underlay"},on:{"click":function($event){$event.stopPropagation();return _vm.treePop('-nested-underlay')}}}):_vm._e(),_vm._l((_vm.getActivePath()),function(node,index){return _c('div',{key:index,staticClass:"tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0",class:[
      index > 0
        ? ''
        : _vm.indexBump === 0
        ? ''
        : _vm.indexBump === 1
        ? 'md:tw-left-4 md:tw-shadow-lg'
        : _vm.indexBump === 2
        ? 'md:tw-left-8 md:tw-shadow-lg'
        : _vm.indexBump === 3
        ? 'md:tw-left-12 md:tw-shadow-lg'
        : _vm.indexBump === 4
        ? 'md:tw-left-16 md:tw-shadow-lg'
        : 'md:tw-left-20 md:tw-shadow-lg',
      node.slotScrolls || _vm.scrolls ? 'tw-overflow-y-auto' : '',
      _vm.isNested && index === 0 ? 'tw-bg-white' : '' ],style:({ zIndex: (index + _vm.indexBump) * 10 }),attrs:{"data-flank":("" + (node.id) + (node.slotScrolls ? '$scrolls-outer-full-w' : '-outer-full-w'))}},[(index === 0)?[_vm._t(("" + (node.id) + (node.slotScrolls ? '$scrolls' : '') + (node.nested ? '$nested' : '')),null,{"dataFlank":("navigator-slot-" + (node.id))})]:(index > 0)?_c('div',{staticClass:"tw-bg-gray-200 tw-opacity-30 tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0",attrs:{"data-flank":("" + (node.id) + (node.slotScrolls ? '$scrolls-pop-underlay' : '-pop-underlay'))},on:{"click":function($event){$event.stopPropagation();return _vm.pop()}}}):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(index > 0)?_c('div',{staticClass:"tw-bg-white tw-absolute tw-right-0 tw-top-0 tw-bottom-0 tw-left-0 md:tw-shadow-lg",class:[
          node.nested
            ? 'md:tw-left-0'
            : index + _vm.indexBump === 1
            ? 'md:tw-left-4'
            : index + _vm.indexBump === 2
            ? 'md:tw-left-8'
            : index + _vm.indexBump === 3
            ? 'md:tw-left-12'
            : index + _vm.indexBump === 4
            ? 'md:tw-left-16'
            : 'md:tw-left-20',
          node.slotScrolls || _vm.scrolls ? 'tw-overflow-y-auto' : '' ],style:({ zIndex: (index + _vm.indexBump) * 10 + 4 }),attrs:{"data-flank":("" + (node.id) + (node.slotScrolls ? '$scrolls-inner-padded' : '-inner-padded'))}},[_vm._t(("" + (node.id) + (node.slotScrolls ? '$scrolls' : '') + (node.nested ? '$nested' : '')),null,{"dataFlank":("navigator-slot-" + (node.id))}),_c('a',{staticClass:"tw-absolute tw-left-4 md:tw-left-5 tw-top-3.5 md:tw-top-4 tw-flex tw-flex-row tw-items-center",class:[
            !node.nested
              ? 'md:tw-left-5'
              : index === 1
              ? 'md:tw-left-8'
              : index === 2
              ? 'md:tw-left-12'
              : index === 3
              ? 'md:tw-left-16'
              : index === 4
              ? 'md:tw-left-20'
              : 'md:tw-left-24' ],style:({ zIndex: (index + _vm.indexBump) * 10 + 6 }),on:{"click":function($event){return _vm.pop()}}},[_c('span',{staticClass:"md:tw-hidden"},[_c('b-icon',{attrs:{"icon":"arrow-left","size":"is-small"}})],1),_c('span',{staticClass:"tw-hidden md:tw-block"},[_c('b-icon',{attrs:{"icon":"arrow-left"}})],1)])],2):_vm._e()])],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }