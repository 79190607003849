import { store } from "@/plugins/store";
import { omerge } from "@/brains/flang";
import router from "@/plugins/router";
import api from "./shelves/api";
import Vue from "vue";
import ReportBugModalVue from "@/components/junk-drawer/ReportBugModal.vue";
import { CreateUserOut, Org } from "@/types/api-schema";
import { getAuthInstance } from "@/plugins/auth";
import { FlankUser } from "@/types/general-flank-types";
import { deserializeError } from "serialize-error";
import { redToast } from "@/components/comflonents/buefyActions";
import StackTrace from "stacktrace-js";

function reportBug(
  that: Vue,
  title: string | null = null,
  content: string | null = null
): void {
  that.$buefy.modal.open({
    parent: that,
    component: ReportBugModalVue,
    hasModalCard: true,
    trapFocus: true,
    props: {
      autogenContent: content,
      autogenTitle: title,
    },
  });
}
export default {
  reportBug,
  async createSandbox(showLoadingPage = true): Promise<void> {
    try {
      if (showLoadingPage) {
        store.commit("setIsLoading", true);
        router.push("/loading");
      }

      const sandbox = await api.post("users/me/sandbox", {});
      const orgs = await api.get("users/me/orgs");

      store.commit("setOrgs", orgs);
      store.commit("setActiveOrgById", sandbox.orgId);

      router.push({
        name: "legosHQRoute",
        params: { orgId: sandbox.orgId },
      });
    } catch {
      alert("error saving to database");
    }
  },
  async addSandboxSQLdb(orgId: string) {
    const resource = await api.post(
      "resources",
      {
        name: "Sandbox SQL db",
        description: "",
        creatorOrgId: orgId,
        resourceType: "db",
        isPublic: false,
        // @ts-ignore
        db: {
          host: "fomf-sandbox-ssdb.database.windows.net",
          port: "1433",
          databaseName: "fomf-ss-sandbox-db",
          databaseSystem: "MSSQL",
        },
        api: null,
      },
      {
        orgPath: true,
      }
    );
    const resourceId = resource.resourceId;
    const creds = await api.post(
      "creds",
      {
        orgId: orgId,
        credType: "db",
        name: "Sandbox SQL Creds",
        syncs: true,
        runs: true,
        stores: false,
        credsDb: {
          username: "fomf-user",
          password: "xQ56dDHWYrLfSfx",
          host: "fomf-sandbox-ssdb.database.windows.net",
          port: "1433",
          databaseName: "fomf-ss-sandbox-db",
          databaseSystem: "MSSQL",
          azKeyVaultRef: "",
        },
        resourceId: resourceId,
        credsAws: null,
        credsAz: null,
        credsApi: null,
      },
      {
        orgPath: true,
      }
    );
    return creds;
  },
  async createOrg(name: string): Promise<Org> {
    const newOrg = await api.post("users/me/orgs", {
      orgName: name,
    });
    const orgs = await api.get("users/me/orgs");

    store.commit("setOrgs", orgs);
    store.commit("setActiveOrgById", newOrg.orgId);
    return newOrg;
  },
  async handleExceptionCausingLogout(that: Vue) {
    const errString = async (e: any, bugDetails: string) => {
      let stack;
      try {
        stack = await StackTrace.fromError(e);
        stack = stack.map((line: any) => line.toString()).join("\n");
      } catch (error) {
        stack = e.stack || "Stack trace not available";
      }
      const errString = `
Name: ${e.name}
Message: ${e.message}
Addtl Details: ${bugDetails}
Stack: ${stack}}
`;
      return errString;
    };
    const exceptionCausingLogout = localStorage.getItem(
      "exceptionCausingLogout"
    );
    if (!exceptionCausingLogout) {
      return;
    }
    console.log("exceptionCausingLogout!");
    const e = deserializeError(JSON.parse(exceptionCausingLogout).e);
    const bugTitle =
      JSON.parse(exceptionCausingLogout)?.bugTitle ?? "Unspecified Error";
    const bugDetails = JSON.parse(exceptionCausingLogout)?.bugDetails ?? "";
    const toast =
      JSON.parse(exceptionCausingLogout)?.toast ?? `Login Error - ${bugTitle}`;
    console.log(e);
    console.log("toast");

    redToast(that, toast, 8000);

    console.log("modal");
    reportBug(that, bugTitle, await errString(e, bugDetails));
    localStorage.removeItem("exceptionCausingLogout");
  },
};
