var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-row"},[_c('b-navbar-item',{staticClass:"tw-text-white",attrs:{"tag":"router-link","to":{
      name: 'legosHQRoute',
      params: { orgId: _vm.activeOrg.orgId },
    }}},[_vm._v("Commands")]),_c('b-navbar-item',{staticClass:"tw-text-white",attrs:{"id":"v-step-3","tag":"router-link","to":{
      name: 'historyRoute',
      params: { orgId: _vm.activeOrg.orgId },
    }}},[_vm._v("History")]),(_vm.activeOrg !== null && _vm.activeOrg.role === 'admin')?_c('b-navbar-item',{staticClass:"tw-text-white",attrs:{"tag":"router-link","to":{
      name: 'analyticsRoute',
      params: { orgId: _vm.activeOrg.orgId },
    }}},[_vm._v("Analytics")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }