import { render, staticRenderFns } from "./LoadingEllipses.vue?vue&type=template&id=f66a17ec&scoped=true&"
import script from "./LoadingEllipses.vue?vue&type=script&lang=ts&"
export * from "./LoadingEllipses.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f66a17ec",
  null
  
)

export default component.exports