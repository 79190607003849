import { htmlSafe, pathJoin, sleep } from "@/brains/flang";
import { zz } from "@/brains/malf";
import $ from "jquery";

export async function kbHighlightParam(legoPath: string, elementPath: string) {
  highlightId(
    "kb-" + htmlSafe(pathJoin(legoPath, elementPath)),
    "highlight-input"
  );
}
export async function kbHighlightRunButton(legoPath: string) {
  highlightId(
    "kb-li-" + htmlSafe(legoPath),
    "highlight-run-btn",
    "lightseagreen",
    10
  );
}
export async function kbHighlightOutput(legoPath: string) {
  highlightId("kb-li-" + htmlSafe(legoPath), "highlight-top-card");
}
export async function kbHighlightCollapsedInstance(legoPath: string) {
  highlightId("kb-li-" + htmlSafe(legoPath));
}
async function highlightId(
  parentId: string,
  childClass = "",
  color = "mediumpurple",
  radius = 5
): Promise<void> {
  if (!parentId.startsWith("#")) parentId = "#" + parentId;
  if (childClass.length > 0 && !childClass.startsWith("."))
    childClass = "." + childClass;
  const selector = parentId + (childClass.length > 0 ? " " + childClass : "");
  // zz({ selector });
  const originalBoxShadow = $(selector).css("box-shadow");
  await sleep(400);
  $(selector).css("box-shadow", `0 0 ${radius}px ${radius}px ${color}`);
  await sleep(1400);
  $(selector).css("box-shadow", originalBoxShadow);
}
