import { sIsJSON } from "../brains/flang";
import { csv2jsonAsync } from "json-2-csv";
import { zz } from "../brains/malf";

const jsonReturnTypes = [
  "application/json",
  "json.table.rowwise",
  "json.multiset.rowwise",
  "error",
  "json",
];

export async function transformPayload(
  payload: string | null,
  payloadDisplayType: string | null
): Promise<any> {
  if (payloadDisplayType === null || payload === null) {
    return null;
  } else if (jsonReturnTypes.includes(payloadDisplayType)) {
    const json = JSON.parse(payload);
    if (payloadDisplayType === "json.multiset.rowwise" && json.length == 1) {
      return json[0].result;
    } else {
      return json;
    }
  } else if (payloadDisplayType === "single_value") {
    if (sIsJSON(payload)) {
      const json = JSON.parse(payload);
      if (Array.isArray(json)) {
        if (typeof json[0] == "object") {
          return json[0][""];
        }
      } else {
        return json;
      }
    }
    return payload;
  } else if (payloadDisplayType === "string") {
    return payload;
  } else if (payloadDisplayType === "csv") {
    //@ts-ignore
    let json;
    let payloadWithoutQuotes;
    let commasFound = false;
    try {
      const payloadWithoutCommas = removeNumberCommas(payload);
      if (payloadWithoutCommas !== payload) {
        commasFound = true;
      }
      const payloadWithoutQuotes = removeInternalQuotationMarks(
        payloadWithoutCommas
      );
      json = await csv2jsonAsync(payloadWithoutQuotes, {
        delimiter: {
          eol: payloadWithoutQuotes.includes("\r\n") ? "\r\n" : "\n",
        },
        //@ts-ignore
        parseValue: parseInput,
      });
    } catch (e) {
      console.log("CSV parsing error", e);
      console.log("payloadWithoutQuotes", payloadWithoutQuotes);
      console.log("payload", payload);
      return null;
    }
    if (commasFound) {
      console.log("commas found");
      //@ts-ignore
      const modifiedList = json.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            key,
            typeof value === "string" ? value.replace(/\^@\^/g, ",") : value, // Here we replace the special character with commas that were originally there
          ])
        )
      );
      json = modifiedList;
    }
    return json;
  } else if (payloadDisplayType === "markdown") {
    // the first replace removes an extra quotation marks around the string
    // the second replace finds all of the python \n and replaces with the same
    // escape sequence to actually function
    return payload; //payload.replace(/^"|"$/g, "").replace(/\\n/g, "\n");
  } else if (payloadDisplayType === "empty") {
    return null;
  } else {
    throw Error(
      `payloadDisplayType=${payloadDisplayType} not supported by transformPayload`
    );
  }
}
function removeNumberCommas(inputString: string) {
  const modifiedCSV = inputString.replace(
    /"(\$?[\d,]+\.?\d*)/g,
    (_, match) => match.replace(/,/g, "^@^") // TLDR: replace commas that follow numbers with a special character, then we add them back later. Otherwise, the parsing gets messed up.
  );
  return modifiedCSV;
}
function removeInternalQuotationMarks(inputString: string) {
  // Regular expression to match external quotes (double or single quotes) and commas after the quotes
  const externalQuotesRegex = /(["'])(.*?)\1/g;
  // Replace external quotes and optional commas with matched content
  let stringWithoutQuotesAndCommas = inputString.replace(
    externalQuotesRegex,
    "$2"
  );
  // Remove any remaining commas immediately after quotes
  stringWithoutQuotesAndCommas = stringWithoutQuotesAndCommas.replace(
    /, /g,
    " "
  );
  return stringWithoutQuotesAndCommas;
}

// removeInternalQuotationMarks('2014-02-12," HELLO ", 51.0'); //=
function parseInput(input: any) {
  // Check if the input is numeric
  const isSomethingNumeric = !isNaN(input) && isFinite(input);
  if (isSomethingNumeric) {
    const bigIntVal = BigInt(input); // Convert to BigInt to handle very large numbers
    const maxJsSafeInt = BigInt("9007199254740991"); // Maximum safe integer in JS
    if (bigIntVal > maxJsSafeInt) {
      return input; // If it's a numeric string representing a number beyond safe limit, return it as a string
    }
  }
  try {
    return JSON.parse(input); // If it's not a "too large" numeric string, try parsing it
  } catch (e) {
    return input; // If JSON.parse fails, return the original input
  }
}
// console.log(parseInput("9400109105155603303256")); // Outputs the string itself: "9400109105155603303256"
// console.log(parseInput("123")); // Outputs number: 123
// console.log(parseInput('{"a":1}')); // Outputs object: { a: 1 }
// console.log(parseInput("Hello")); // Outputs string: "Hello"
// parseInput(
//   "    HEAD -    CREATE PROCEDURE [dbo].[spQueueItems_GetNextNative]      @QueueItemID AS        BIGINT       OUT ,      @WorkItem AS           VARCHAR(255) OUT ,      @QueueID               TINYINT ,      @MaxProcessAttempts AS SMALLINT ,      @RestartDate AS        DATETIME ,      @MachineID AS          SMALLINT = NULL	  WITH RECOMPILE  AS  BEGIN        BEGIN TRANSACTION ;      INSERT INTO dbo.QueueLocks      (          QueueID      )      VALUES      (@QueueID) ;        DECLARE @ProcessAttempts AS INT ;      SELECT   TOP (1)               @QueueItemID = QueueItemID ,               @ProcessAttempts = ProcessAttempts ,               @WorkItem = WorkItem      FROM     dbo.QueueItems      WHERE    QueueID = @QueueID               AND Queued = 1               AND QueueItemID <> ISNULL(@QueueItemID, 0)      ORDER BY Priority DESC ,               QueueItemID ASC ; -- Get the oldest item with the highest priority        IF @QueueItemID IS NOT NULL      BEGIN          DECLARE @RestartProcessing AS DATETIME = NULL ;          IF @ProcessAttempts < @MaxProcessAttempts              SET @RestartProcessing = @RestartDate ;            UPDATE dbo.QueueItems          SET    StartedProcessing = GETDATE() ,                 RestartProcessing = @RestartProcessing ,                 ProcessAttempts = ProcessAttempts + 1 ,                 Queued = 0 ,                 ProcessedByMachineID = @MachineID          WHERE  QueueItemID = @QueueItemID ;      END ;        DELETE FROM dbo.QueueLocks      WHERE QueueID = @QueueID ;      COMMIT TRANSACTION ;  END ;  "
// ); //=
// parseInput(
//   '"    HEAD -    CREATE PROCEDURE [dbo].[spQueueItems_GetNextNative]      @QueueItemID AS        BIGINT       OUT ,      @WorkItem AS           VARCHAR(255) OUT ,      @QueueID               TINYINT ,      @MaxProcessAttempts AS SMALLINT ,      @RestartDate AS        DATETIME ,      @MachineID AS          SMALLINT = NULL	  WITH RECOMPILE  AS  BEGIN        BEGIN TRANSACTION ;      INSERT INTO dbo.QueueLocks      (          QueueID      )      VALUES      (@QueueID) ;        DECLARE @ProcessAttempts AS INT ;      SELECT   TOP (1)               @QueueItemID = QueueItemID ,               @ProcessAttempts = ProcessAttempts ,               @WorkItem = WorkItem      FROM     dbo.QueueItems      WHERE    QueueID = @QueueID               AND Queued = 1               AND QueueItemID <> ISNULL(@QueueItemID, 0)      ORDER BY Priority DESC ,               QueueItemID ASC ; -- Get the oldest item with the highest priority        IF @QueueItemID IS NOT NULL      BEGIN          DECLARE @RestartProcessing AS DATETIME = NULL ;          IF @ProcessAttempts < @MaxProcessAttempts              SET @RestartProcessing = @RestartDate ;            UPDATE dbo.QueueItems          SET    StartedProcessing = GETDATE() ,                 RestartProcessing = @RestartProcessing ,                 ProcessAttempts = ProcessAttempts + 1 ,                 Queued = 0 ,                 ProcessedByMachineID = @MachineID          WHERE  QueueItemID = @QueueItemID ;      END ;        DELETE FROM dbo.QueueLocks      WHERE QueueID = @QueueID ;      COMMIT TRANSACTION ;  END ;  "'
// ); //=

export function convertPayloadDisplayTypeToExt(
  payloadDisplayType: string
): string {
  if (
    payloadDisplayType === "csv" ||
    payloadDisplayType === "json.table.rowwise"
  ) {
    return "csv";
  } else if (payloadDisplayType === "markdown") {
    return "md";
  } else if (jsonReturnTypes.includes(payloadDisplayType)) {
    return "json";
  }
  return "ERROR";
}

export async function downloadFile(url: string) {
  const filename = removeDuplicateFileExtension(url);
  zz("url", url);
  zz("new filename", filename);
  const link = document.createElement("a");
  link.href = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}

export function downloadCSVWithoutPresignedURL(data: any, filename: string) {
  // Create a CSV string from the data
  const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(data);

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = csvContent;
  link.download = filename;

  // Simulate a click on the anchor element to trigger the download
  link.click();
}

export function jsonListRowwiseToCSV(data: any, delimiter = ",") {
  // Extract keys from the first object in the list
  const keys = Object.keys(data[0]);

  // Create the header row
  const headerRow = keys.join(delimiter);

  // Create the data rows
  const dataRows = data.map((obj: any) =>
    keys.map((key) => obj[key]).join(delimiter)
  );

  // Combine the header row and data rows
  const csvContent = [headerRow, ...dataRows].join("\n");

  return csvContent;
}

function removeDuplicateFileExtension(file_url: string) {
  const extensions = [".csv", ".json", ".txt"];
  const filename = file_url.split("?")[0];
  const queryParams = file_url.split("?")[1];
  zz("queryParams", queryParams);
  for (let i = 0; i < extensions.length; i++) {
    const extension = extensions[i];
    const extensionRegex = new RegExp(`${extension}${extension}$`);
    if (extensionRegex.test(filename)) {
      zz("found ittttttt");
      const newFileName = filename.replace(extensionRegex, extension);
      return newFileName + "?" + queryParams;
    } else {
      console.log("no go for", extension);
    }
  }
  console.log("queary params now", queryParams);
  return filename + "?" + queryParams;
}
