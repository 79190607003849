var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sync.state == 'error')?_c('ErrorBox',{staticClass:"tw-mb-5",attrs:{"error":_vm.error,"contactAdminTitle":("There was an error with sync ID " + (_vm._oget(
      _vm.sync,
      'response.syncId'
    )) + " on credential ID " + (_vm.sync.credsId)),"bugReportTitle":("Sync Error (SID: " + (_vm._oget(_vm.sync, 'response.syncId')) + ", CID:\n  " + (_vm.sync.credsId) + ")"),"bugReportBody":JSON.stringify(_vm.sync, null, '\t')},on:{"tryAgain":function($event){return _vm.$emit('tryAgain')}}}):_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-10"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-3"},[_c('div',{staticClass:"tw-font-bold tw-text-2xl"},[_vm._v("Additions")]),(_vm.sync.state == 'posting_sync_stage_i')?_c('div',[_vm._v(" In progress"),_c('LoadingEllipses')],1):(
          _vm.sync.state == 'posting_sync_stage_ii' || _vm.sync.state == 'completed'
        )?_c('div',[(_vm._okeys(_vm._omerge(_vm.sync.additions, _vm.sync.undeletions)).length > 0)?_c('SyncLegoTable2',{attrs:{"sync":_vm.sync,"segment":_vm._omerge(_vm.sync.additions, _vm.sync.undeletions)}}):_c('div',[_vm._v("No legos")])],1):_vm._e()]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-3"},[_c('div',{staticClass:"tw-font-bold tw-text-2xl"},[_vm._v("Deletions")]),(_vm.sync.state == 'posting_sync_stage_i')?_c('div',[_vm._v(" In progress"),_c('LoadingEllipses')],1):(
          _vm.sync.state == 'posting_sync_stage_ii' || _vm.sync.state == 'completed'
        )?_c('div',[(_vm._okeys(_vm.sync.deletions).length > 0)?_c('SyncLegoTable2',{attrs:{"sync":_vm.sync,"segment":_vm.sync.deletions}}):_c('div',[_vm._v("No legos")])],1):_vm._e()]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-3"},[_c('div',{staticClass:"tw-font-bold tw-text-2xl"},[_vm._v("Updates")]),(_vm.sync.state == 'posting_sync_stage_i')?_c('div',[_vm._v(" In progress"),_c('LoadingEllipses')],1):(
          _vm.sync.state == 'posting_sync_stage_ii' || _vm.sync.state == 'completed'
        )?_c('div',[(_vm._okeys(_vm.sync.updates).length > 0)?_c('SyncLegoTable2',{attrs:{"sync":_vm.sync,"segment":_vm.sync.updates}}):_c('div',{staticClass:"tw-text-gray-400"},[_vm._v("No legos")])],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }