import { adClient } from "@/plugins/adClient";
import { auth0Client } from "./auth0Client";
import { demoClient } from "./demoClient";

export async function apiUserObj(idaas: string): Promise<Record<string, any>> {
  if (idaas == "auth0") {
    const auth0User = await (await auth0Client()).getUser();
    if (auth0User) {
      return {
        userId: auth0User.sub,
        email: auth0User.email,
        username: null,
        pictureUrl: auth0User.picture,
        name: auth0User.name,
        nickname: auth0User.nickname,
        auth0UpdatedAt: auth0User.updated_at,
      };
    }
  } else if (idaas == "ad") {
    const account = adClient().getUser();
    if (account) {
      return {
        userId: account.localAccountId,
        email: null,
        username: account.username,
        pictureUrl: null,
        name: account.name,
        nickname: null,
        auth0UpdatedAt: null,
      };
    }
  } else if (idaas == "demo") {
    const demoRandomId = demoClient().getUser();
    return {
      userId: demoRandomId,
      email: null,
      username: null,
      pictureUrl: null,
      name: demoRandomId,
      nickname: null,
      auth0UpdatedAt: null,
    };
  }

  return {};
}

export function redirectingFromAuth0Splash(): boolean {
  return (
    window.location.search.includes("code=") &&
    window.location.search.includes("state=")
  );
}
