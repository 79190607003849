import { isObject, lfirst, oitems, pytruthy } from "./flang";

import { ERunGET, ERunNoPayloadGET } from "../types/api-schema";

export function zz(...s: any[]) {
  if (s.length === 0) {
    const fakeError = new Error();
    const stack = fakeError.stack;
    if (stack && stack.split("\n").length >= 3) {
      console.log(stack.split("\n")[2]);
    }
  } else if (s.length === 1) {
    const first = lfirst(s);
    if (isObject(first)) {
      for (const [k, v] of oitems(first)) {
        console.log(k, v);
      }
    } else {
      console.log(first);
    }
  } else if (s.length > 1) {
    console.log(...s);
  }
}

export function fetchLegoPathOfRun(run: ERunNoPayloadGET | ERunGET) {
  if (pytruthy(run.kitInstanceId)) {
    return `root.ki_${run.kitInstanceId}.li_${run.legoInstanceId}`;
  }
  return `root.li_${run.legoInstanceId}`;
}

export function fetchFullPathFromKiliId(kiliId: string): string {
  const kiliSplit = kiliId.split("_");
  return `root.ki_${kiliSplit[0]}.li_${kiliSplit[1]}`;
}

export function getLiIdFromLegoPath(legoPath: string) {
  // legoPath is "root.ki_393.li_6359" and it returns 6359 or legoPath is "root.li_7164" and it returns 7164. It returns the integer after "li_"
  const parts = legoPath.split(".");
  const lastPart = parts[parts.length - 1];
  const id = lastPart.split("_")[1];
  return id;
}
// getLiIdFromLegoPath("root.ki_393.li_6359"); //=
// getLiIdFromLegoPath("root.li_7164"); //=

export function getKiIdFromLegoPath(legoPath: string) {
  // legoPath is "root.ki_393.li_6359" and it returns 393. it returns the integer after ki_
  const parts = legoPath.split(".");
  const lastPart = parts[parts.length - 2];
  const id = lastPart.split("_")[1];
  return id;
}

export function getKiliIdOrLiId(legoPath: string) {
  // if legoPath is root.li_7162, it returns 7162 but if legoPath is root.ki_393.li_6359, it returns 393_6359
  const parts = legoPath.split(".");
  const lastPart = parts[parts.length - 1];
  const id = lastPart.split("_")[1];
  if (parts.length === 2) {
    return ["li", id];
  }
  const secondLastPart = parts[parts.length - 2];
  const kiId = secondLastPart.split("_")[1];
  return ["kili", `${kiId}_${id}`];
}

getKiliIdOrLiId("root.ki_393.li_6359"); //=
getKiliIdOrLiId("root.li_7164"); //=
