<template>
  <div
    class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen tw-bg-purple-100"
  >
    <p
      class="tw-font-bold tw-text-gray-700 tw-text-2xl tw-my-6 tw-w-full tw-text-center"
    >
      Create your new org
    </p>
    <div class="tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-md tw-w-96">
      <!-- <p
        class="tw-text-purple-600 tw-font-bold tw-text-4xl tw-w-full tw-text-center tw-my-2"
      >
        flank
      </p> -->
      <!-- <p class="tw-font-bold tw-text-gray-400 tw-text-xl tw-my-2 tw-w-full">
        Create account
      </p> -->
      <div class="tw-mb-4">
        <label for="user-name" class="tw-block tw-mb-2">Your name:</label>
        <input
          id="user-name"
          type="text"
          v-model="userName"
          @input="generateOrgName"
          class="tw-w-full tw-py-2 tw-px-4 tw-rounded tw-border tw-border-gray-200 tw-focus:outline-none tw-focus:border-purple-500"
          maxlength="40"
        />
      </div>

      <!-- <div>
        <label for="org-name" class="tw-block tw-mb-2">Your org's name:</label>
        <div class="tw-flex tw-items-center tw-mb-2">
          <input
            id="org-name"
            type="text"
            v-model="orgName"
            class="tw-w-full tw-py-2 tw-px-4 tw-rounded tw-border tw-border-gray-200 tw-focus:outline-none tw-focus:border-purple-500"
            :disabled="!customOrgName"
            maxlength="40"
          />
          <input
            type="checkbox"
            id="toggle-custom-name"
            class="tw-ml-4"
            v-model="customOrgName"
          />
          <label for="toggle-custom-name" class="tw-ml-1">Custom name</label>
        </div>
      </div> -->
      <div>
        <label for="org-name" class="tw-block tw-mb-2">Your org's name:</label>
        <div class="tw-flex tw-flex-col tw-items-left tw-mb-2 tw-gap-y-4">
          <input
            id="org-name"
            type="text"
            v-model="orgName"
            class="tw-w-full tw-py-2 tw-px-4 tw-rounded tw-border tw-border-gray-200 tw-focus:outline-none tw-focus:border-purple-500"
            :disabled="!customOrgName"
            maxlength="40"
          />
          <span class="tw-flex tw-gap-x-1">
            <input
              type="checkbox"
              id="toggle-custom-name"
              v-model="customOrgName"
            />
            <label for="toggle-custom-name" class="tw-ml-1 tw-whitespace-nowrap"
              >Custom org name</label
            >
          </span>
        </div>
      </div>

      <button
        :disabled="!(userName && orgName)"
        @click="onDone"
        class="tw-bg-purple-500 tw-text-white tw-w-full tw-py-2 tw-rounded tw-mt-6 tw-focus:outline-none tw-opacity-60"
        :class="{
          'tw-opacity-100': userName && orgName,
          'tw-cursor-pointer': userName && orgName,
          'tw-cursor-not-allowed': !(userName && orgName),
        }"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
import MissionControl from "@/MissionControl";
export default {
  data() {
    return {
      userName: "",
      orgName: "",
      customOrgName: false,
    };
  },
  watch: {
    customOrgName(newValue) {
      if (!newValue) this.orgName = this.userName + "'s org";
    },
    userName(newValue) {
      if (newValue === "" && !this.customOrgName) {
        this.orgName = "";
      }
    },
  },
  methods: {
    generateOrgName() {
      if (!this.customOrgName) {
        let firstName = this.userName.split(" ");
        this.orgName = firstName[0] + "'s org";
      }
    },
    async onDone() {
      console.log("Navigating");
      await MissionControl.createOrg(this.orgName);
      console.log("nav to story");
      // this.$router.push({
      //   name: "demoTheStoryRoute",
      //   params: {
      //     orgId: this.$store.state.activeOrg.orgId,
      //   },
      // });
      this.$router.push({
        name: "legosHQRoute",
        params: {
          orgId: this.$store.state.activeOrg.orgId,
          //   postCreationSyncing: "",
        },
      });
    },
  },
};
</script>
