var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-pb-10 md:tw-pb-20",class:_vm.padDesktop
      ? _vm.tightDesktop
        ? 'md:tw-px-10 md:tw-pt-10'
        : 'md:tw-px-32 md:tw-pt-8'
      : '',attrs:{"data-flank":"header-body"}},[(_vm.$slots.header || (_vm.headerText && _vm.headerText.length > 0))?_c('div',{staticClass:"tw-sticky tw-top-0 md:tw-static tw-bg-white tw-text-xl md:tw-text-2xl tw-font-bold tw-pb-1.5 md:tw-pb-3 md:tw-mb-8 tw-mb-6 tw-pt-2.5 md:tw-pt-12",class:[
      _vm.padMobile ? 'tw-px-4' : '',
      _vm.padDesktop ? 'md:tw-px-1' : '',
      _vm.hideMobileHeader
        ? 'tw-hidden md:tw-flex tw-flex-row'
        : 'tw-flex tw-flex-row',
      _vm.borderBottom ? 'tw-border-b-2' : '' ],staticStyle:{"z-index":"5"}},[(_vm.onStack)?_c('div',{staticClass:"tw-w-8 tw-flex-shrink-0 md:tw-hidden"}):_vm._e(),_c('div',{staticClass:"tw-flex-1 tw-truncate"},[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.headerText))]},{"dataFlank":"header-body-header"})],2),_c('div',{staticClass:"md:tw-hidden tw-w-8 tw-flex-shrink-0"})]):_vm._e(),_c('div',{class:[_vm.padMobile ? 'tw-px-4' : '', _vm.padDesktop ? 'md:tw-px-1' : '']},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }