import { render, staticRenderFns } from "./ReceiveSidePanel.vue?vue&type=template&id=6353f926&scoped=true&"
import script from "./ReceiveSidePanel.vue?vue&type=script&lang=ts&"
export * from "./ReceiveSidePanel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6353f926",
  null
  
)

export default component.exports